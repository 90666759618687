<template>
  <div class="storageSampleDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.storageSample.id ? '编辑' : '新增' }}仓储取样</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="仓储取样日期">
            <el-date-picker style="width: 100%;" v-model="data.storageSample.sampleDate" type="date" @change="handleChangeSampleDate" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车号">
            <el-input v-model="data.storageSample.truckNumber" :max="100" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储">
            <el-select v-model="data.storageSample.storage" filterable>
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户">
            <el-select v-model="data.storageSample.custom" filterable>
              <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="销售品名">
            <!-- <el-autocomplete style="width: 100%;" v-model="data.storageSample.customNote" :fetch-suggestions="filterCustomNoteList" value-key="customNote" @select="handleSelect" placeholder="选择提示，会自动补全仓储和配比" /> -->
            <el-select v-model="data.storageSample.customNote" @change="handleCustomNoteSelect" filterable :placeholder="data.storageSample.sampleDate ? '请选择' : '请先选择发货日期'">
              <el-option v-for='customNote in show.customNoteList' :key="customNote.id" :label="customNote.customNote" :value="customNote.customNote" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="配比">
            <el-table :data="data.storageSample.storageSampleMixDetailList" border height="210px">
              <el-table-column label="" width="80" header-align="center" align="center">
                <template #default="scope">
                  <el-link type="danger" @click="delRow(scope.row)" v-if="scope.row.product">删除</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="product" label="煤矿品名" header-align="center">
                <template #default="scope">
                  <el-select v-model="scope.row.product" filterable @change="checkRowProduct(scope.row)">
                    <el-option style="width: 100%;" v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="mixNumber" label="占比" header-align="center">
                <template #default="scope">
                  <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="scope.row.mixNumber" />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储化验·大卡">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.storageSample.storageDiweifareliangKc" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户化验·大卡">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.storageSample.customDiweifareliangKc" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="化验差值">
            <el-text v-if="data.storageSample.storageDiweifareliangKc && data.storageSample.customDiweifareliangKc">
              {{ math.plus(data.storageSample.customDiweifareliangKc, -data.storageSample.storageDiweifareliangKc) }}
            </el-text>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  customList: [],
  storageList: [],
  productList: [],
  customNoteList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "storageSampleDetail")
const data = reactive({
  storageSample: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { storageSampleMixDetailList: [] }
})
data.storageSample.storageSampleMixDetailList.push({})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})
const handleChangeSampleDate = () => {
  // 重新查询配比
  show.customNoteList = []
  if (data.storageSample.sampleDate) {
    const useDate = util.parseTime(data.storageSample.sampleDate, '{y}-{m}-{d}')
    api.get('/backend/customNoteToMix/all', { params: { useDate } }).then(res => {
      show.customNoteList = res.list
    })
  }
  // 清除已选择的配比
  data.storageSample.customNote = null
  data.storageSample.storageSampleMixDetailList = [{}]
}

const delRow = (row) => {
  data.storageSample.storageSampleMixDetailList = data.storageSample.storageSampleMixDetailList.filter(item => item.product != row.product)
}

const checkRowProduct = (row) => {
  const l1 = data.storageSample.storageSampleMixDetailList.length
  const l2 = data.storageSample.storageSampleMixDetailList.filter(item => item.product != row.product).length
  if (l1 != l2 + 1) {
    ElMessage.error("煤矿品名重复")
    row.product = null
  } else {
    data.storageSample.storageSampleMixDetailList.push({})
  }
}

const save = () => {
  const param = JSON.parse(JSON.stringify(data.storageSample))
  param.sampleDate = util.parseTime(param.sampleDate, '{y}-{m}-{d}')
  param.storageSampleMixDetailList = data.storageSample.storageSampleMixDetailList.filter(item => item.product).map(item => {
    return {
      product: item.product,
      mixNumber: item.mixNumber,
    }
  })
  param.operateUser = null
  param.operateDatetime = null
  if (param.id) {
    api.post('/backend/storageSample/setById', param).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  } else {
    api.post('/backend/storageSample/add', param).then(() => {
      ElMessage.success('保存成功')
      router.go(-1)
    })
  }
}

// const filterCustomNoteList = () => {
//   return show.customNoteList.filter(c => {
//     return c.customNote.indexOf(data.storageSample.customNote) >= 0
//   })
// }

// const handleSelect = (customNote) => {
//   data.storageSample.storage = customNote.storage
//   data.storageSample.storageSampleMixDetailList = customNote.mixList
//   data.storageSample.storageSampleMixDetailList.push({})
// }

const handleCustomNoteSelect = (customNoteString) => {
  const customNote = show.customNoteList.filter(x => x.customNote == customNoteString)[0]
  data.storageSample.storage = customNote.storage
  data.storageSample.storageSampleMixDetailList = customNote.mixList
  data.storageSample.storageSampleMixDetailList.push({})
}
</script>

<style lang="less">
.storageSampleDetail {
  margin: auto;
  max-width: 900px;
}
</style>